/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherMissionSlotDto, FlightFlow, MissionType } from '../../../../../../../backend/gen';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { Input, styled } from '@mui/material';
import { StyledRowDiv } from './AirportResourceInfo';
import { RoundSwitch } from '../../../../../../../components/form/RoundSwitch/RoundSwitch';
import { TimeField } from '../../../../../../../components/form/TimeField/TimeField';
import { theme } from '../../../../../../../theme';
import { FlightOptionType } from './FlightSelector';

type MissionStaffExcept = { exceptional: boolean; staff: boolean };

export function MissionTypeSelector({
    mission,
    setMission,
    selectedFlight,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: React.Dispatch<React.SetStateAction<Partial<DispatcherMissionSlotDto>>>;
    selectedFlight: FlightOptionType;
}) {
    const [missionStaffExcept, setMissionStaffExcept] = useState<MissionStaffExcept>({
        exceptional: false,
        staff: false,
    });

    const handleMissionType = useCallback(
        ({ staff, state }: { staff: boolean; state: boolean }) => {
            let tempMissionStaffExcept: MissionStaffExcept;
            if (staff) {
                tempMissionStaffExcept = { ...missionStaffExcept, staff: state };
                setMissionStaffExcept({ ...missionStaffExcept, staff: state });
            } else {
                tempMissionStaffExcept = { ...missionStaffExcept, exceptional: state };
                setMissionStaffExcept({ ...missionStaffExcept, exceptional: state });
            }
            if (tempMissionStaffExcept.exceptional && tempMissionStaffExcept.staff) {
                setMission({
                    ...mission,
                    type: MissionType.EX_STAFF,
                    refTime: selectedFlight?.flight?.refTime,
                });
            } else if (tempMissionStaffExcept.exceptional) {
                setMission({
                    ...mission,
                    type: MissionType.EX_PAX,
                    refTime: selectedFlight?.flight?.refTime,
                });
            } else if (tempMissionStaffExcept.staff) {
                setMission({ ...mission, type: MissionType.STAFF, refTime: selectedFlight?.flight?.refTime });
            } else {
                setMission({ ...mission, type: MissionType.PAX });
            }
        },
        [mission, missionStaffExcept, selectedFlight, setMission],
    );

    const onChangeUpdateStart = useCallback(
        newStartTime => setMission({ ...mission, refTime: newStartTime }),
        [mission, setMission],
    );

    function onChangeUpdateStaff(value: string) {
        setMission({ ...mission, staffNum: parseInt(value) });
    }

    useEffect(() => {
        setMissionStaffExcept({
            exceptional: false,
            staff: false,
        });
    }, [selectedFlight, setMission]);

    return mission?.flight ? (
        <>
            {mission.flight?.flow === FlightFlow.DEP && (
                <StyledRowDiv>
                    <StyledRoundSwitch
                        onChange={event => handleMissionType({ staff: false, state: event.target.checked })}
                        disabled={!!mission.missionId}
                        checked={mission?.type === MissionType.EX_STAFF || mission?.type === MissionType.EX_PAX}
                    />
                    <StyledSwitchTitle>
                        <FormattedMessage id={'flightMissionForm.exceptionalMission'} />
                    </StyledSwitchTitle>
                </StyledRowDiv>
            )}
            {(mission?.type === MissionType.EX_STAFF || mission?.type === MissionType.EX_PAX) && (
                <StyledRowSpaceBetweenDiv>
                    <div>
                        <StyledSectionTitle>
                            <FormattedMessage id={'flightMissionForm.pickupLocation'} />
                        </StyledSectionTitle>
                        <StyledInput
                            placeholder="free text"
                            disableUnderline
                            value={mission?.pickUpLocation || ''}
                            onChange={event =>
                                setMission({
                                    ...mission,
                                    pickUpLocation: event.target.value,
                                })
                            }
                            type={'text'}
                            inputProps={{ maxlength: 45 }}
                        ></StyledInput>
                    </div>
                    <div>
                        <StyledSectionTitle>
                            <FormattedMessage id={'flightMissionForm.dropOffLocation'} />
                        </StyledSectionTitle>
                        <StyledInput
                            placeholder="free text "
                            disableUnderline
                            value={mission?.dropOffLocation || ''}
                            onChange={event =>
                                setMission({
                                    ...mission,
                                    dropOffLocation: event.target.value,
                                })
                            }
                            type={'text'}
                            inputProps={{ maxlength: 45 }}
                        ></StyledInput>
                    </div>
                </StyledRowSpaceBetweenDiv>
            )}
            <StyledRowSpaceBetweenDiv>
                <StyledRowDiv>
                    <StyledRoundSwitch
                        onChange={event => handleMissionType({ staff: true, state: event.target.checked })}
                        disabled={!!mission.missionId}
                        checked={mission?.type === MissionType.EX_STAFF || mission?.type === MissionType.STAFF}
                    />
                    <StyledSwitchTitle>
                        <FormattedMessage id={'flightMissionForm.staffMission'} />
                    </StyledSwitchTitle>
                </StyledRowDiv>
                {(mission?.type === MissionType.EX_STAFF || mission?.type === MissionType.STAFF) && (
                    <div>
                        <StyledSectionTitle>Staff</StyledSectionTitle>
                        <StyledInput
                            placeholder="staff"
                            disableUnderline
                            type={'number'}
                            inputProps={{ min: 1 }}
                            onChange={event => onChangeUpdateStaff(event.target.value)}
                            value={mission.staffNum || 0}
                        ></StyledInput>
                    </div>
                )}
            </StyledRowSpaceBetweenDiv>
            {(mission?.type === MissionType.EX_STAFF ||
                mission?.type === MissionType.EX_PAX ||
                mission?.type === MissionType.STAFF) && (
                <StyledStartTimeContainer>
                    <StyledStartTimePicker
                        name={'startTime'}
                        date={new Date()}
                        value={mission.refTime || mission.flight?.refTime}
                        onChange={onChangeUpdateStart}
                        required
                        label={
                            <span className={'startTimeText'}>
                                <FormattedMessage id={'flightMissionForm.startTime'} />
                            </span>
                        }
                    />
                </StyledStartTimeContainer>
            )}
        </>
    ) : null;
}

const StyledInput = styled(Input)`
    min-width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #f1f7fb;
    padding: 10px;
    color: #0a3b5d;
    margin-bottom: 20px;
`;

const StyledRoundSwitch = styled(RoundSwitch)`
    opacity: 0.5;
`;

const StyledSectionTitle = styled('div')`
    opacity: 1;
    color: rgba(10, 59, 93, 1);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
`;

const StyledSwitchTitle = styled('div')`
    opacity: 1;
    color: #082940;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
`;

const StyledRowSpaceBetweenDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledStartTimePicker = styled(TimeField)`
    align-self: end;
    max-width: 165px;
    color: rgba(10, 59, 93, 1);
    & .MuiFilledInput-root {
        background-color: #f1f7fb !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0) !important;
        }
    }
`;

const StyledStartTimeContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 170px;
`;
