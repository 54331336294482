import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
`;

export const StyledMissionInfoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: black;
    font-size: 25px;
    font-weight: bold;
`;

export const StyledMissionPickUpDropoffContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 480px;
    font-size: 20px;
    font-weight: 500;
    color: #082940;
    text-transform: uppercase;
    position: absolute;
    top: 120px;
    left: 320px;
    gap: 80px;
`;

export const StyledLocationInMarquee = styled('div')`
    margin-left: 20px;
`;

export const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const StyledArrowIconContainer = styled(Box)`
    display: flex;
    justify-content: center;
    position: relative;
`;

export const StyledTours = styled(Box)`
    position: absolute;
    bottom: 30px;
`;

export const StyledStepsContainer = styled(Box)`
    margin-left: 55px;
    overflow: hidden;
    position: relative;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 21px;
    font-weight: bold;
    &.top-fadeout {
        mask: linear-gradient(to top, #000 70%, #0000 100%);
    }
    &.bottom-fadeout {
        mask: linear-gradient(to bottom, #000 40%, #0000 100%);
    }
    &.both-fadeout {
        mask: linear-gradient(#0000 0%, #000 35%, #000 65%, #0000 100%);
    }
`;

export const StyledStepContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const StyledStepPendingCheckbox = styled(Box)`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #d5dde3;
`;
