import React, { memo, useEffect, useRef, useState } from 'react';
import {
    StyledAirportName,
    StyledAirportNameContainer,
    StyledArrivalIcon,
    StyledBusDisplayContentDepContainer,
    StyledCenterContainer,
    StyledDashedLine,
    StyledDepartureIcon,
    StyledFlightDelayed,
    StyledFlightInfoContainer,
    StyledFlightNumber,
    StyledLeftSideContainer,
    StyledRightSideContainer,
    StyledTimeAndIcon,
    StyledTimeAtDestinationInfoText,
} from './BusDisplayContentDep.style';
import { FlightInfoDto } from '../../../../backend/gen';
import {
    busDisplayTranslations,
    checkIfAirlineLogoExists,
    cycleObject,
    DepartureInfoMode,
    LanguageMode,
} from '../busDisplay.utils';
import { formatInTimeZone } from 'date-fns-tz';
import Marquee from 'react-fast-marquee';
import { useBoolean } from '../../../../hooks/useBoolean';
import { FormattedTime } from '../../../../components/time/FormattedTime';

export const BusDisplayContentDep = memo(function BusDisplayContentDep({ flightInfo }: { flightInfo: FlightInfoDto }) {
    const airlineLogoExists = useBoolean(false);
    useEffect(() => {
        checkIfAirlineLogoExists(
            flightInfo.airline?.icao?.toLowerCase(),
            () => airlineLogoExists.setTrue(),
            () => airlineLogoExists.setFalse(),
        );
    }, [airlineLogoExists, flightInfo.airline?.icao]);

    // If flight is not delayed, change language every 10 seconds
    const [currentLanguage, setCurrentLanguage] = useState<LanguageMode>(LanguageMode.English);
    const langCycleTimerRef = useRef<NodeJS.Timer>();
    useEffect(() => {
        if (!flightInfo.delayed) {
            langCycleTimerRef.current = setInterval(() => {
                setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
            }, 10000);
        }
        return () => {
            clearInterval(langCycleTimerRef.current);
        };
    }, [currentLanguage, flightInfo.delayed]);

    // If flight is delayed, change language after every full info cycle (default -> delayed)
    const infoCycleTimerRef = useRef<NodeJS.Timer>();
    const [infoToShow, setInfoToShow] = useState<DepartureInfoMode>(DepartureInfoMode.DEFAULT);
    const [cycleCount, setCycleCount] = useState(1);
    useEffect(() => {
        if (flightInfo.delayed) {
            infoCycleTimerRef.current = setInterval(() => {
                setInfoToShow(
                    cycleObject({
                        obj: DepartureInfoMode,
                        current: infoToShow,
                    }),
                );
                if (cycleCount === Object.keys(DepartureInfoMode).length) {
                    setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
                    setCycleCount(1);
                } else {
                    setCycleCount(cycleCount + 1);
                }
            }, 5000);
        }
        return () => {
            clearInterval(infoCycleTimerRef.current);
        };
    }, [currentLanguage, cycleCount, flightInfo.controlled, flightInfo.delayed, flightInfo.oversizedBelt, infoToShow]);

    const formattedAirportName = flightInfo.ades[currentLanguage]
        ? flightInfo.ades[currentLanguage].charAt(0).toUpperCase() +
          flightInfo.ades[currentLanguage].slice(1).toLowerCase()
        : '-';

    return (
        <StyledBusDisplayContentDepContainer>
            <StyledLeftSideContainer>
                <StyledTimeAndIcon>
                    <span>
                        <FormattedTime>{flightInfo.sobt}</FormattedTime>
                    </span>
                    <StyledDepartureIcon />
                    <StyledDashedLine />
                </StyledTimeAndIcon>
                <StyledFlightInfoContainer>
                    {infoToShow === DepartureInfoMode.DELAYED ? (
                        <StyledFlightDelayed>
                            {busDisplayTranslations.departureFlightDelayed[currentLanguage] + ' '}
                            <FormattedTime>{flightInfo.eobt}</FormattedTime>
                        </StyledFlightDelayed>
                    ) : (
                        <StyledFlightNumber>{flightInfo.flightNumber}</StyledFlightNumber>
                    )}
                </StyledFlightInfoContainer>
            </StyledLeftSideContainer>

            <StyledCenterContainer>
                {airlineLogoExists.value && (
                    <img src={`images/airlines/${flightInfo.airline?.icao?.toLowerCase()}.png`} alt="airline-logo" />
                )}
                <StyledAirportNameContainer>
                    {formattedAirportName.length >= 10 ? (
                        <Marquee gradient={false} speed={50} style={{ overflowY: 'hidden' }}>
                            <StyledAirportName>{formattedAirportName}</StyledAirportName>
                        </Marquee>
                    ) : (
                        formattedAirportName
                    )}
                </StyledAirportNameContainer>
            </StyledCenterContainer>

            <StyledRightSideContainer>
                <StyledTimeAndIcon>
                    <StyledDashedLine />
                    <StyledArrivalIcon />
                    <span> {formatInTimeZone(flightInfo.sibt, flightInfo.adesTimeZone, 'HH:mm')}</span>
                </StyledTimeAndIcon>
                <StyledTimeAtDestinationInfoText>
                    {busDisplayTranslations.departureTimeAtDestination[currentLanguage]}
                </StyledTimeAtDestinationInfoText>
            </StyledRightSideContainer>
        </StyledBusDisplayContentDepContainer>
    );
});
